import {Helmet} from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Badge,
    Chip,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    ButtonGroup,
    ToggleButtonGroup,
    ToggleButton,
    FormControlLabel, ListSubheader,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import {StepByStepTable} from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import PageTitle from "../components/PageTitle";
import {
    useAddSessionDisciplineDetailMutation, useAdminSetApproveStatusMutation,
    useEditSessionDisciplineControlTypeMutation,
    useGetSessionStatementsQuery, useInitSessionDisciplineDetailMutation, useRemoveSessionDisciplineDetailMutation,
    useRemoveSessionDisciplineMutation
} from "../store/session/sessionApi";
import {ExpandMoreOutlined} from "@mui/icons-material";
import copyObject from "../utils/copyObject";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import simpleSort from "../utils/simpleSort";
import {useListEmployeesQuery} from "../store/employee/employeeApi";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

export default function SessionManagementByAdminPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [copyOfData, setCopyOfData] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const {data} = useGetSessionStatementsQuery({sessionId: id});
    const {data: availableEmployees} = useListEmployeesQuery();
    const [editControlType] = useEditSessionDisciplineControlTypeMutation();
    const [removeSessionDisciplineApiAction] = useRemoveSessionDisciplineMutation();
    const [removeSessionDisciplineDetailApiAction] = useRemoveSessionDisciplineDetailMutation();
    const [addSessionDisciplineDetailApiAction] = useAddSessionDisciplineDetailMutation();
    const [adminSetApproveStatus] = useAdminSetApproveStatusMutation();
    const [initSessionDisciplineDetail] = useInitSessionDisciplineDetailMutation();
    const [groups, setGroups] = useState([]);
    const [sessionApproveStatus, setSessionApproveStatus] = useState(0);

    useEffect(() => {
        if (data === null || data === undefined)
            return;

        const copy = copyObject(data);
        copy.disciplines = copy.disciplines.sort((l, r) => l.disciplineName === r.disciplineName ? (l.controlType > r.controlType ? 1 : 0) : l.disciplineName > r.disciplineName ? 1 : -1)
        setCopyOfData(copy);
        copy.disciplines.forEach((item) => {
            item.groups.forEach((gr) => {
                if (groups.find((existing) => existing === gr.groupName) === undefined) {
                    groups.push(gr.groupName)
                }
            })
        });
        
        setSessionApproveStatus(copy.approveStatus)
    }, [data]);

    function applyVisualChanges() {
        setRefresh(!refresh);
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date) {
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const handleSave = async () => {
        setIsSaving(true);

        // await createAttestation({courseId: id, startDate, endDate}).unwrap().then((fulfilled) => {
        //     if (fulfilled?.success) {
        //         navigate('/facultyattestations');
        //     } else {
        //         fulfilled.errors.map((item) => {
        //             alert(item);
        //             return 1;
        //         });
        //
        //         setIsSaving(false);
        //     }
        // });
    };

    function nameOfControlType(statementType) {
        switch (statementType) {
            case 0:
                return 'экзамен';
            case 1:
                return 'зачёт';
            case 2:
                return 'дифф. зачёт';
            case 3:
                return 'курсовая работа';
            case 4:
                return 'курсовой проект';
            default:
                return '-';
        }
    }

    function passingTypeName(passingType) {
        switch (passingType) {
            case 0:
                return 'Основная';
            case 1:
                return 'Дополнительная';
            case 2:
                return 'Комиссионная';
            case 10:
                return 'Внеплановая';
            default:
                return '-';
        }
    }

    async function setControlType(value, item) {
        console.log(`Устанавливаем тип ${value}`)
        item.controlType = parseInt(value, 10);
        item.controlTypeEditMode = false;
        applyVisualChanges()

        await editControlType({
            data: {
                sessionDisciplineId: item.sessionDisciplineId,
                controlType: item.controlType
            }
        }).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                alert('Сохранено')
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });
            }
        });
    }

    async function removeSessionDiscipline(sessionDisciplineId) {
        const discipline = copyOfData.disciplines.find((item) => item.sessionDisciplineId === sessionDisciplineId);
        if (discipline === undefined) {
            alert('Дисциплина отсутствует в списке')
            return;
        }

        // eslint-disable-next-line no-restricted-globals
        if (!confirm("Вы уверены")) {
            return;
        }

        await removeSessionDisciplineApiAction({data: {sessionDisciplineId}}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                copyOfData.disciplines.splice(copyOfData.disciplines.indexOf(discipline), 1);
                applyVisualChanges();
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });
            }
        });
    }

    async function removeSessionDisciplineDetail(sessionDisciplineDetailId, sessionDisciplineId) {
        const discipline = copyOfData.disciplines.find((item) => item.sessionDisciplineId === sessionDisciplineId);
        if (discipline === undefined) {
            alert('Дисциплина отсутствует в списке')
            return;
        }

        const sdd = discipline.groups.find((item) => item.sessionDisciplineDetailId === sessionDisciplineDetailId);
        if (sdd === undefined) {
            alert('Сдача отсутствует в списке')
            return;
        }

        // eslint-disable-next-line no-restricted-globals
        if (!confirm("Вы уверены")) {
            return;
        }

        await removeSessionDisciplineDetailApiAction({data: {sessionDisciplineDetailId}}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                discipline.groups.splice(discipline.groups.indexOf(sdd), 1);
                applyVisualChanges();
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });
            }
        });
    }

    async function addSessionDisciplineDetail(item) {
        alert('Страница перезагрузится для завершения операции')
        console.log(`Добавляем SDD для ${item.newSessionDisciplineDetailGroupName} - ${item.newSessionDisciplineDetailPassingType}`)

        await addSessionDisciplineDetailApiAction({
            data: {
                sessionDisciplineId: item.sessionDisciplineId,
                groupName: item.newSessionDisciplineDetailGroupName,
                passingType: item.newSessionDisciplineDetailPassingType
            }
        }).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                window.location.reload();
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });
            }
        });
    }

    async function editSessionDisciplineDetail(group) {
        // initSessionDisciplineDetail
        const employeeIds = [];
        group.employees.forEach((item) => {
            if (employeeIds.find((e) => e === parseInt(item.key, 10)) === undefined) {
                employeeIds.push(parseInt(item.key, 10))
            }
        })

        const request = {
            sessionDisciplineDetailId: group.sessionDisciplineDetailId,
            eventDateTime: group.eventDateTime,
            eventPlace: group.eventPlace,
            employees: employeeIds
        };

        await initSessionDisciplineDetail({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                group.editSessionDisciplineDetailMode = false;
                applyVisualChanges();
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });
            }
        });
    }

    function removeEmployee(group, employeeId) {
        console.log(employeeId)
        const employee = group.employees.find((item) => item.key === employeeId);
        if (employee === undefined) {
            alert('Преподаватель не найден');
            return;
        }

        group.employees.splice(group.employees.indexOf(employee), 1);
        applyVisualChanges();
    }

    function addEmployee(group, employee) {
        group.employees.push({
            key: employee.employeeId.toString(),
            value: `${employee.lastName} ${employee.firstName} ${employee.middleName}`
        });

        applyVisualChanges();
    }

    async function changeApproveStatus(value) {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm('Вы действительно хотите изменить статус?')) {
            return;
        }
        
        const newStatus = parseInt(value, 10);
        await adminSetApproveStatus({data: {sessionId: id, approveStatus: newStatus}}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                setSessionApproveStatus(newStatus >= 0 && newStatus <= 7 || newStatus === 100 ? newStatus : 0);
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });
            }
        });
    }

    function statusColor(status) {
        return sessionApproveStatus === status
            ? 'contained'
            : 'outlined';
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Администрирование сессии`}/>

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress/> : <></>}
                            Администрирование сессии {id}
                        </Typography>
                    </Stack>

                    <Typography variant={"h6"}>{data?.courseName}</Typography>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreOutlined/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography>
                                            <strong>Статус сессии</strong>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ButtonGroup size={'small'}
                                                     color={'primary'}
                                                     onClick={(event) => {changeApproveStatus(event.target.value)}}
                                                     orientation={'vertical'}>
                                            <Button value={0} variant={statusColor(0)}>Не отправлено на утверждение</Button>
                                            <Button value={1} variant={statusColor(1)}>На утверждении в УМУ</Button>
                                            <Button value={2} variant={statusColor(2)}>На утверждении в диспетчерской</Button>
                                            <Button value={3} variant={statusColor(3)}>Возвращено для внесения изменений по решению
                                                диспетчерской</Button>
                                            <Button value={4} variant={statusColor(4)}>Запрос на прерывание отправлен в УМУ</Button>
                                            <Button value={5} variant={statusColor(5)}>Запрос на прерывание отправлен в диспетчерскую</Button>
                                            <Button value={6} variant={statusColor(6)}>Запрос на прерывание отправлен в УМУ и
                                                диспетчерскую</Button>
                                            <Button value={7} variant={statusColor(7)}>Возвращено для внесения изменений по решению УМУ</Button>
                                            <Button value={100} variant={statusColor(100)}>Утверждено</Button>
                                        </ButtonGroup>
                                    </AccordionDetails>
                                </Accordion>

                                {copyOfData?.disciplines.map((item) => {
                                    return <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreOutlined/>}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Typography>
                                                <strong>{item.disciplineName}</strong> ({nameOfControlType(item.controlType)})
                                                <br/>
                                                <small>Кафедра {item.chairName}</small>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Stack spacing={2}>
                                                <small>ID сессионной дисциплины: {item.sessionDisciplineId}</small>
                                                <ButtonGroup size={'small'} orientation={'horizontal'}>
                                                    <Button onClick={(event) => {
                                                        item.sessionDisciplineDetailCreationMode = !item.sessionDisciplineDetailCreationMode;
                                                        applyVisualChanges()
                                                    }}>Добавить сдачу</Button>
                                                    <Button onClick={(event) => {
                                                        item.controlTypeEditMode = !item.controlTypeEditMode;
                                                        applyVisualChanges()
                                                    }}>Изменить тип сдачи</Button>
                                                    <Button onClick={(event) => {
                                                        removeSessionDiscipline(item.sessionDisciplineId)
                                                    }} color={'error'}>Удалить дисциплину</Button>
                                                </ButtonGroup>

                                                {item.controlTypeEditMode
                                                    ? <>
                                                        <ButtonGroup size={'small'} onClick={(event) => {
                                                            setControlType(event.target.value, item)
                                                        }}>
                                                            <Button value={0}>Экзамен</Button>
                                                            <Button value={1}>Зачёт</Button>
                                                            <Button value={2}>Дифф. зачёт</Button>
                                                            <Button value={3}>Курс. работа</Button>
                                                            <Button value={4}>Курс. проект</Button>
                                                        </ButtonGroup>
                                                    </>
                                                    : <></>}

                                                {item.sessionDisciplineDetailCreationMode
                                                    ? <Card>
                                                        <CardContent>
                                                            <Stack spacing={1}>
                                                                <Typography>Группа</Typography>
                                                                <Select size={'small'} onChange={(event) => {
                                                                    item.newSessionDisciplineDetailGroupName = event.target.value;
                                                                    applyVisualChanges()
                                                                }}>
                                                                    {simpleSort(groups).map((groupName) => {
                                                                        return <MenuItem
                                                                            value={groupName}>{groupName}</MenuItem>
                                                                    })}
                                                                </Select>

                                                                <ButtonGroup size={'small'} onClick={(event) => {
                                                                    item.newSessionDisciplineDetailPassingType = parseInt(event.target.value, 10);
                                                                    applyVisualChanges()
                                                                }}>
                                                                    <Button value={0}
                                                                            variant={item.newSessionDisciplineDetailPassingType === 0 ? 'contained' : 'outlined'}>Основная</Button>
                                                                    <Button value={1}
                                                                            variant={item.newSessionDisciplineDetailPassingType === 1 ? 'contained' : 'outlined'}>Дополнительная</Button>
                                                                    <Button value={2}
                                                                            variant={item.newSessionDisciplineDetailPassingType === 2 ? 'contained' : 'outlined'}>Комиссионная</Button>
                                                                </ButtonGroup>

                                                                <Button size={'small'}
                                                                        variant={'contained'}
                                                                        onClick={(event) => {
                                                                            addSessionDisciplineDetail(item)
                                                                        }}
                                                                        disabled={item.newSessionDisciplineDetailPassingType === undefined || item.newSessionDisciplineDetailPassingType === null || item.newSessionDisciplineDetailGroupName === undefined || item.newSessionDisciplineDetailGroupName === null}>Сохранить</Button>
                                                            </Stack>
                                                        </CardContent>
                                                    </Card>
                                                    : <></>}

                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>ID сдачи</TableCell>
                                                            <TableCell>Группа</TableCell>
                                                            <TableCell>Тип сдачи</TableCell>
                                                            <TableCell align={'center'}>Ведомость</TableCell>
                                                            <TableCell>Действия</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {item.groups.sort((l, r) => l.groupName > r.groupName ? 1 : -1).map((group) => {
                                                            return <TableRow>
                                                                <TableCell>{group.sessionDisciplineDetailId}</TableCell>
                                                                <TableCell>{group.groupName}</TableCell>
                                                                <TableCell>{passingTypeName(group.passingType)}</TableCell>
                                                                <TableCell align={'center'}>
                                                                    {group.statementId > 0
                                                                        ? <>
                                                                            <Button
                                                                                href={`/statementresults/${group.statementId}`}
                                                                                target={'_blank'}>№{group.statementNumber}</Button>
                                                                            <br/>
                                                                            ID: {group.statementId}
                                                                        </>
                                                                        : 'Не создана'}

                                                                    {group.editSessionDisciplineDetailMode
                                                                        ? <Stack spacing={2}>
                                                                            <FormControlLabel labelPlacement={'top'}
                                                                                              control={<TextField
                                                                                                  size={'small'}
                                                                                                  defaultValue={group.eventDateTime}
                                                                                                  onChange={(event) => {
                                                                                                      group.eventDateTime = event.target.value;
                                                                                                      console.log(event.target.value)
                                                                                                  }}
                                                                                                  type={'datetime-local'}/>}
                                                                                              label={'Дата и время проведения'}/>

                                                                            <FormControlLabel labelPlacement={'top'}
                                                                                              control={<TextField
                                                                                                  size={'small'}
                                                                                                  defaultValue={group.eventPlace}
                                                                                                  onChange={(event) => {
                                                                                                      group.eventPlace = event.target.value
                                                                                                  }}
                                                                                                  type={'text'}/>}
                                                                                              label={'Место проведения'}/>

                                                                            <Typography>Преподаватели</Typography>
                                                                            <ol>
                                                                                {group.employees.map((empl) => {
                                                                                    return <li>{empl.value} <IconButton
                                                                                        color={'error'}
                                                                                        onClick={() => {
                                                                                            removeEmployee(group, empl.key)
                                                                                        }}
                                                                                        size={'small'}><RemoveCircleIcon/></IconButton>
                                                                                    </li>
                                                                                })}
                                                                            </ol>

                                                                            <Select size={'small'}
                                                                                    value={group.selectedEmployee}
                                                                                    onChange={(event) => {
                                                                                        addEmployee(group, event.target.value)
                                                                                    }}>
                                                                                {availableEmployees?.data.map((chair) => {
                                                                                    return chair.employees.map((empl) => {
                                                                                        return <MenuItem
                                                                                            value={empl}>{empl.lastName} {empl.firstName} {empl.middleName} ({chair.chairName})</MenuItem>
                                                                                    })
                                                                                })}
                                                                            </Select>

                                                                            <Button variant={'contained'}
                                                                                    onClick={() => {
                                                                                        editSessionDisciplineDetail(group)
                                                                                    }}>Сохранить</Button>
                                                                        </Stack>
                                                                        : <></>}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <ButtonGroup size={'small'}
                                                                                 orientation={'vertical'}>
                                                                        <Button onClick={() => {
                                                                            group.editSessionDisciplineDetailMode = !group.editSessionDisciplineDetailMode;
                                                                            applyVisualChanges()
                                                                        }}>Редактировать сдачу</Button>
                                                                        <Button color={'error'} onClick={(event) => {
                                                                            removeSessionDisciplineDetail(group.sessionDisciplineDetailId, item.sessionDisciplineId)
                                                                        }}>Удалить сдачу</Button>
                                                                    </ButtonGroup>
                                                                </TableCell>
                                                            </TableRow>
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </Stack>
                                        </AccordionDetails>
                                    </Accordion>
                                })}
                            </Stack>
                        </CardContent>
                    </Card>
                    <br/>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}