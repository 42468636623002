import PropTypes from 'prop-types';
// @mui
import {
    TableRow,
    TableCell,
    TableBody,
    Stack,
    Typography,
    Paper,
    Table,
    Container,
    Button,
    CardContent,
    TableContainer,
    TableHead,
    Checkbox,
    Select,
    ToggleButtonGroup,
    ToggleButton,
    InputLabel, MenuItem, FormControl, Input, Hidden, Link
} from '@mui/material';
import {useEffect, useRef, useState} from "react";
import {filter} from "lodash";
import {JournalListHead} from "../journal";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
    Downloading,
    Looks,
    PanoramaFishEye,
    Remove,
    RemoveCircleOutline, RemoveCircleOutlined,
    Upload,
    UploadFileOutlined,
    Warehouse
} from "@mui/icons-material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import TextField from "@mui/material/TextField";
import FileUploadButton from "./FileUploadButton";

// ----------------------------------------------------------------------

StudentGradeEditTable.propTypes = {
    students: PropTypes.array.isRequired,
    useStatementsView: PropTypes.bool.isRequired,
    usePracticeView: PropTypes.bool,
    controlType: PropTypes.number,
    prohibitSessionStatementsEditing: PropTypes.bool,
    noPrimaryGrade: PropTypes.bool,
    noExtraGrade: PropTypes.bool,
    lessonDate: PropTypes.object,
    pairNumber: PropTypes.number
};

// ----------------------------------------------------------------------


export default function StudentGradeEditTable(props) {
    const [refresh, setRefresh] = useState(false);
    const action = useRef(null);
    const [checkUncheckAllState, setCheckUncheckAllState] = useState(true);

    function checkUncheckAllStateChanged(value) {
        for (let i = 0; i < props.students.length; i += 1) {
            props.students[i].attended = value;
        }

        setCheckUncheckAllState(value);
        console.log(`check/uncheck all to state ${value}`);
    }

    function inverseAttendanceForAll() {
        for (let i = 0; i < props.students.length; i += 1) {
            props.students[i].attended = !props.students[i].attended;
        }

        applyVisualChanges();
    }

    function setExtraGradeType(row, value) {
        row.extraGradeType = value;
        applyVisualChanges();
    }

    function certificateTypeName(id) {
        switch (id) {
            case 0:
                return 'Справка';
            case 1:
                return 'Распоряжение деканата';
            case 2:
                return 'Заявление';
            case 3:
                return 'График дежурств';
            default:
                return '-';
        }
    }

    function handleGradeChange(row, value) {
        if (row.grade !== undefined && row.grade !== null) {
            row.grade = value;
        } else {
            row.gradeId = value;
        }

        applyVisualChanges()
    }

    function handleExtraGradeChange(row, value) {
        row.extraGrade = value;
        applyVisualChanges()
    }

    function attendedChanged(row, value) {
        row.attended = value;
        applyVisualChanges();
    }

    function applyVisualChanges() {
        setRefresh(!refresh);
    }

    function isCourseWork() {
        return (props.controlType === 3 || props.controlType === 4)
            && props.useStatementsView
            && props.usePracticeView !== true;
    }

    function toDateString(date) {
        if (date === null)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    return (
        <Stack spacing={2}>
            <Stack direction="row" justifyContent={"right"} spacing={2}>
                {!props.useStatementsView
                    ? <>
                        <FormControlLabel control={<Checkbox defaultChecked
                                                             checked={checkUncheckAllState}
                                                             onChange={(event, value) => checkUncheckAllStateChanged(value)}/>}
                                          label="Отметить всех/снять выделение"/>
                        <Button variant={"outlined"} onClick={() => inverseAttendanceForAll()}>Инверсия</Button>
                    </>
                    : <></>}
            </Stack>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography>#</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>ФИО</Typography>
                        </TableCell>
                        {props.noPrimaryGrade !== true
                            ? <TableCell>
                                <Typography>Оценка</Typography>
                            </TableCell>
                            : <></>}
                        {!props.useStatementsView && props.noExtraGrade !== true
                            ? (<TableCell>
                                <Typography>Доп. оценка</Typography>
                            </TableCell>)
                            : <></>}
                        {!props.useStatementsView
                            ? (<TableCell>
                                <Typography>Присутствие</Typography>
                            </TableCell>)
                            : <></>}
                        {isCourseWork()
                            ? <TableCell align={"right"}>Рецензия/Работа</TableCell>
                            : <></>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.students?.map((row, index) => {
                        const {
                            studentId,
                            lastName,
                            firstName,
                            middleName,
                            attended,
                            grade,
                            gradeId,
                            notAllowed,
                            extraGrade,
                            extraGradeType,
                            skippedHours,
                            totalHours,
                            certificatesOfSkipping,
                            registryDocumentGuid,
                            registryDocumentNumber,
                            registryDocumentDate,
                            preliminaryGradeName,
                            preliminaryGradeId,
                            isByAverage
                        } = row;

                        let byAverage = row.isByAverage ?? false;
                        const filteredCertificates = certificatesOfSkipping?.filter((item) => item.fromDate <= props.lessonDate && item.toDate >= props.lessonDate);

                        return (<TableRow key={studentId}
                                          hover
                                          style={{cursor: 'pointer'}}
                                          onClick={(event) => {
                                              if (event.target.tagName === "TD")
                                                  attendedChanged(row, !attended)
                                          }}>
                            <TableCell>
                                <Typography fontWeight="bold">{index + 1}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography fontWeight="bold">{lastName}</Typography>
                                <Typography>{firstName} {middleName}</Typography>
                                {!props.useStatementsView ?
                                    (
                                        <Typography
                                            fontSize="small">{skippedHours}/{totalHours} ({(totalHours === 0 ? 0 : (skippedHours * 100 / totalHours)).toFixed(2)}%
                                            пропусков)</Typography>
                                    )
                                    : null
                                }
                                {certificatesOfSkipping !== undefined && certificatesOfSkipping !== null && certificatesOfSkipping.length > 0
                                    ? filteredCertificates
                                        .map((item) => {
                                            return <Typography fontSize={"small"}
                                                               color={"orange"}>Имеется {certificateTypeName(item.documentType).toLowerCase()} с {toDateString(item.fromDate)} ({item.fromPair} пара)
                                                по {toDateString(item.toDate)} ({item.toPair} пара)</Typography>
                                        })
                                    : <></>}
                            </TableCell>
                            {props.noPrimaryGrade !== true
                                ? <TableCell>
                                    <Stack spacing={2}>
                                        {notAllowed
                                            ? <><Typography color={'error'}>Не допущен(а)</Typography></>
                                            : <></>}
                                        <ToggleButtonGroup orientation="horizontal"
                                                           exclusive
                                                           fullWidth
                                                           size={"small"}
                                                           value={grade ?? gradeId}
                                                           aria-label="Оценка"
                                                           color="primary"
                                                           disabled={props.prohibitSessionStatementsEditing }
                                                           onChange={(event, value) => handleGradeChange(row, value)}>
                                            <ToggleButton value={5} area-label="Отлично"
                                                          color="success">{props.controlType !== 1 ? '5' : 'Зачтено'}</ToggleButton>
                                            {props.controlType !== 1
                                                ? <ToggleButton value={4} area-label="Хорошо"
                                                                color="success">4</ToggleButton>
                                                : <></>}
                                            {props.controlType !== 1
                                                ? <ToggleButton value={3} area-label="Удовлетворительно"
                                                                color="warning">3</ToggleButton>
                                                : <></>}
                                            <ToggleButton value={2} area-label="Неудовлетворительно"
                                                          color="error">{props.controlType !== 1 ? '2' : 'Не зачтено'}</ToggleButton>
                                            {props.useStatementsView
                                                ?
                                                <ToggleButton value={6} area-label="н/я"
                                                              color="error">н/д</ToggleButton>
                                                : <></>}
                                            {props.useStatementsView
                                                ?
                                                <ToggleButton value={7} area-label="н/я"
                                                              color="error">н/я</ToggleButton>
                                                : <></>}
                                            <ToggleButton value={1} area-label="-" color="info">-</ToggleButton>
                                        </ToggleButtonGroup>

                                        {preliminaryGradeId > 1 && props.useStatementsView
                                            ? <FormControlLabel control={<Checkbox size={'small'} value={byAverage} onChange={(event, checked) => { row.isByAverage = checked; byAverage = checked; applyVisualChanges()}}/>}
                                                                label={<Typography size={'small'}>Оценка по среднему баллу: {preliminaryGradeName}</Typography>}/>
                                            : <></>}

                                        {registryDocumentGuid !== null && registryDocumentGuid !== undefined
                                            ? <Typography fontSize={'small'}>Перезачтено на основании документа
                                                <Link href={`https://fs.kubsau.ru/File/${registryDocumentGuid}`}
                                                      target={'_blank'}>
                                                    {` ${registryDocumentNumber} от ${toDateString(registryDocumentDate)}`}</Link>
                                            </Typography>
                                            : <></>}

                                        {
                                            props.usePracticeView
                                                ? <FileUploadButton existingFileUrl={row.attestationListUrl}
                                                                    maxFileSize={200 * 1024}
                                                                    title={'Аттестационный лист'}
                                                                    uploadingEnabled={`true`}
                                                                    onFileUploaded={(newUrl, guid) => {
                                                                        row.attestationListUrl = newUrl;
                                                                        row.attestationListGuid = guid;
                                                                        applyVisualChanges()
                                                                    }}
                                                                    onFileDelete={() => {
                                                                        row.attestationListUrl = null;
                                                                        row.attestationListGuid = null;
                                                                        applyVisualChanges();
                                                                    }}/>
                                                : <></>}

                                        {
                                            props.usePracticeView
                                                ? <FileUploadButton existingFileUrl={row.reportUrl}
                                                                    maxFileSize={2 * 1024 * 1024}
                                                                    title={'Отчёт'}
                                                                    uploadingEnabled={`true`}
                                                                    onFileUploaded={(newUrl, guid) => {
                                                                        row.reportUrl = newUrl;
                                                                        row.reportGuid = guid;
                                                                        applyVisualChanges()
                                                                    }}
                                                                    onFileDelete={() => {
                                                                        row.reportUrl = null;
                                                                        row.reportGuid = null;
                                                                        applyVisualChanges();
                                                                    }}/>
                                                : <></>}

                                        {
                                            props.usePracticeView
                                                ? <FileUploadButton existingFileUrl={row.documentsUrl}
                                                                    maxFileSize={2 * 1024 * 1024}
                                                                    title={'Документы'}
                                                                    uploadingEnabled={`true`}
                                                                    onFileUploaded={(newUrl, guid) => {
                                                                        row.documentsUrl = newUrl;
                                                                        row.documentsGuid = guid;
                                                                        applyVisualChanges()
                                                                    }}
                                                                    onFileDelete={() => {
                                                                        row.documentsUrl = null;
                                                                        row.documentsGuid = null;
                                                                        applyVisualChanges();
                                                                    }}/>
                                                : <></>}


                                        {props.controlType === 3 || props.controlType === 4
                                            ? <><TextField value={row.theme}
                                                           size={"small"}
                                                           placeholder={"Тема работы"}
                                                           onInput={(event) => {
                                                               row.theme = event.target.value;
                                                               applyVisualChanges();
                                                           }}/></>
                                            : <></>}
                                    </Stack>
                                </TableCell>
                                : <></>}
                            {!props.useStatementsView && props.noExtraGrade !== true
                                ? (<TableCell>
                                    <Stack spacing={2}>
                                        <ToggleButtonGroup orientation="horizontal"
                                                           fullWidth
                                                           size={"small"}
                                                           exclusive
                                                           value={extraGrade}
                                                           aria-label="Доп. оценка"
                                                           color="primary"
                                                           onChange={(event, value) => handleExtraGradeChange(row, value)}>
                                            <ToggleButton value={5} area-label="Отлично"
                                                          color="success">5</ToggleButton>
                                            <ToggleButton value={4} area-label="Хорошо" color="success">4</ToggleButton>
                                            <ToggleButton value={3} area-label="Удовлетворительно"
                                                          color="warning">3</ToggleButton>
                                            <ToggleButton value={2} area-label="Неудовлетворительно"
                                                          color="error">2</ToggleButton>
                                            <ToggleButton value={6} area-label="н/д" color="error">н/д</ToggleButton>
                                            <ToggleButton value={1} area-label="-" color="info">-</ToggleButton>
                                        </ToggleButtonGroup>

                                        <FormControl
                                            size={"small"}
                                            fullWidth>
                                            <InputLabel id="extra-grade-type-select-label">Тип оценки</InputLabel>
                                            <Select
                                                action={action}
                                                labelId="extra-grade-type-select-label"
                                                id="extra-grade-type-simple-select"
                                                label="Тип оценки"
                                                value={extraGradeType}
                                                onChange={(event, value) => setExtraGradeType(row, event.target.value)}
                                            >
                                                <MenuItem value={0}>Не указано</MenuItem>
                                                <MenuItem value={1}>Тестирование</MenuItem>
                                                <MenuItem value={2}>Лабораторная работа</MenuItem>
                                                <MenuItem value={3}>Контрольная работа</MenuItem>
                                                <MenuItem value={4}>Расчетно-графическая работа</MenuItem>
                                                <MenuItem value={5}>Домашнее задание</MenuItem>
                                                <MenuItem value={6}>Другое</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                </TableCell>)
                                : (null)}
                            {!props.useStatementsView
                                ? (<TableCell>
                                    <Checkbox checked={attended}
                                              onChange={(event, value) => attendedChanged(row, value)}/>
                                </TableCell>)
                                : (null)}
                            {isCourseWork()
                                ? <TableCell>
                                    <FileUploadButton existingFileUrl={row.reviewFileUrl}
                                                      existingFileGuid={row.reviewFileGuid}
                                                      maxFileSize={200 * 1024}
                                                      title={'Рецензия'}
                                                      uploadingEnabled={`true`}
                                                      onFileUploaded={(newUrl, guid) => {
                                                          row.reviewFileUrl = newUrl;
                                                          row.reviewFileGuid = guid;
                                                          applyVisualChanges()
                                                      }}
                                                      onFileDelete={() => {
                                                          row.reviewFileUrl = null;
                                                          row.reviewFileGuid = null;
                                                          applyVisualChanges();
                                                      }}/>

                                    <FileUploadButton existingFileUrl={row.courseWorkFileUrl}
                                                      existingFileGuid={row.courseWorkFileGuid}
                                                      maxFileSize={1.5 * 1024 * 1024}
                                                      title={'Работа'}
                                                      uploadingEnabled={`true`}
                                                      onFileUploaded={(newUrl, guid) => {
                                                          row.courseWorkFileUrl = newUrl;
                                                          row.courseWorkFileGuid = guid;
                                                          applyVisualChanges()
                                                      }}
                                                      onFileDelete={() => {
                                                          row.courseWorkFileUrl = null;
                                                          row.courseWorkFileGuid = null;
                                                          applyVisualChanges();
                                                      }}/>
                                </TableCell>
                                : <></>}
                        </TableRow>);
                    })}
                </TableBody>
            </Table>
        </Stack>
    );
}
