import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {Button, Card, CircularProgress, Container, Stack, Table, TableBody, Typography} from "@mui/material";
import {GeneralLayout} from "../layouts/GeneralLayout";
import StatementCard from "../components/session/StatementCard";
import StudentGradeEditTable from "../sections/@dashboard/common/StudentGradeEditTable";
import {
    useGetEmployeeStatementDetailsQuery,
    usePostEmployeeEditStatementMutation
} from "../store/statement/statementApi";
import {useEffect, useState} from "react";
import MainLayout from "./main";
import PageTitle from "../components/PageTitle";
import { useGetUserDetailsQuery } from "../store/user/userApi";

export default function EmployeeEditStatementPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useGetEmployeeStatementDetailsQuery({statementId: id});
    const [copyOfData, setCopyOfData] = useState([]);
    const [employeeEditStatementQuery] = usePostEmployeeEditStatementMutation({ statementId: id, students: copyOfData });
    const { data: userData } = useGetUserDetailsQuery();
    
    useEffect(() => {
        if (data === null || data === undefined)
            return;

        copyStudents(data?.data?.students);
    }, [data]);
    
    function copyStudents(students){
        console.log(students);
        if (students === null || students === undefined)
            return;
        
        setCopyOfData(copyObject(students));
    }

    function copyObject(source) {
        if (source === null || source === undefined)
            return {};

        return JSON.parse(JSON.stringify(source));
    }

    function toDateString(date) {
        if (date === null || date === undefined)
            return '';
        
        return new Date(date).toLocaleDateString("ru-RU");
    }

    const handleSave = async () => {
        setIsSaving(true);

        await employeeEditStatementQuery({statementId: id, students: copyOfData}).unwrap().then((fulfilled) => {
            if (fulfilled.isSuccess) {
                navigate(`/mystatements`);
            }
            else {
                alert(fulfilled.message);
                setIsSaving(false);
            }
        });
    };

    function statementTypeNameByNumber(number) {
        if (number === null || number === undefined)
            return '-';
        
        switch (number) {
            case 0:
                return 'Основная';
            case 1:
                return 'Дополнительная';
            case 2:
                return 'Комиссионная';
            case 10:
                return 'Внеплановая';
            default:
                return 'Неизвестный тип ведомости';
        }
    }
    
    function controlTypeName(controlTypeId) {
        switch (controlTypeId) {
            case 0: return 'экзаменационной';
            case 1: return 'зачётной';
            case 2: return 'зачётной';
            default: return '';
        }
    }

    return <MainLayout>
        <PageTitle title={`Редактировать ведомость`} />

        <Container>
            <Stack
                padding="30px 0px 0px 0px"
                direction="column"
                spacing={2}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {data === null || data === undefined
                            ? <CircularProgress />
                            : <></>}
                        Редактирование {controlTypeName(data?.data.controlTypeId)} ведомости {`(${statementTypeNameByNumber(data?.data.numberInOrder)})`} {data?.data.statementNumber}
                    </Typography>
                </Stack>

                <Stack spacing={2}>
                    <Typography>
                        <strong>Дисциплина: </strong>
                        {data?.data.disciplineName}
                    </Typography>

                    <Typography>
                        <strong>Группа: </strong>
                        {data?.data.groupName}
                    </Typography>

                    <Typography>
                        <strong>Рекомендуемая крайняя дата закрытия: </strong>
                        {toDateString(data?.data.deadline)}
                    </Typography>
                </Stack>

                <Card>
                    <StudentGradeEditTable useStatementsView={'true'}
                        controlType={data?.data.controlTypeId}
                        students={copyOfData}
                        prohibitSessionStatementsEditing={userData?.userFlags?.prohibitSessionStatementsEditing} />

                    <br />
                    
                    <Stack justifyContent="space-between">
                        {
                            !isSaving
                                ? <Button variant="contained" onClick={handleSave} disabled={userData?.userFlags?.prohibitSessionStatementsEditing}>Сохранить</Button>
                                : <CircularProgress/>
                        }
                    </Stack>
                </Card>
                <br />
            </Stack>
        </Container>
    </MainLayout>
}