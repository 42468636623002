import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container, Link, Radio, RadioGroup,
    Stack,
    Table, TableBody, TableCell,
    TableHead,
    TableRow, ToggleButton, ToggleButtonGroup,
    Typography
} from "@mui/material";
import {GeneralLayout} from "../layouts/GeneralLayout";
import {useGetSessionDisciplineDetailsInfoQuery, usePostCreateStatementMutation} from "../store/session/sessionApi";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import PageTitle from "../components/PageTitle";
import CheckIcon from "@mui/icons-material/Check";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function CreateStatementPage() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [refresh, setRefresh] = useState(false);
    const {data} = useGetSessionDisciplineDetailsInfoQuery({sessionDisciplineDetailsId: id});
    const [isSaving, setIsSaving] = useState(false);
    const [createStatement] = usePostCreateStatementMutation();
    const [request, setRequest] = useState({
        sessionDisciplineDetailId: id,
        statementNumber: '',
        deadline: formatDate(calculateDeadline(new Date()))
    });
    const [students, setStudents] = useState([]);
    const [links, setLinks] = useState([]);
    
    useEffect(() => {
        if (data === null || data === undefined)
            return;
        
        setStudents(copyStudents(data.data.students));
        
        console.log(`deadline is empty: ${data.data.deadline === null || data.data.deadline === undefined}. Value is: ${data.data.deadline}`)
        if (data.data.deadline === null || data.data.deadline === undefined)
            return;
        
        console.log(toDateString(data.data.deadline));
        request.deadline = formatDate(new Date(data.data.deadline));
        students.forEach((item) => {
            console.log(item)
        })

        applyVisualChanges();
    }, [data]);
    
    function copyStudents(obj) {
        return data.data.students.map((item) => {
            const copy = copyObject(item);
            copy.notAllowed = 0;
            return copy;
        })
    }

    function getCorrectFsLink(link) {
        // костыль для того, чтобы можно было скачивать файлы из тестового хранилища

        const cachedLink = links.find((item) => item.link === link);
        if (cachedLink !== undefined) {
            console.log('Ссылка найдена в кэше')
            window.open(cachedLink.correctLink, '_blank')
            return;
        }
        console.log('ссылка не найдена в кэше, определяем корректную форму')

        try {
            fetch(link)
                .then((resp) => {
                    console.log('response', resp);
                    if (resp.status === 404) {
                        console.log(`${link} = 404`)
                        links.push({
                            link,
                            correctLink: `${link}?useTest=1`
                        })

                        window.open(`${link}?useTest=1`, '_blank')
                    }
                    else if (resp.status === 200 || resp.status === 300) {
                        links.push({
                            link,
                            correctLink: `${link}`
                        })

                        window.open(`${link}`, '_blank')
                    }
                }).catch((resp) => {
                    console.log('error', resp)
                    links.push({
                        link,
                        correctLink: `${link}?useTest=1`
                    })
                })
        } catch (err) {
            console.log('catch', err)
            console.log(links)
        }
    }

    function copyObject(source) {
        if (source === null || source === undefined)
            return {};

        return JSON.parse(JSON.stringify(source));
    }

    function toDateString(date) {
        return new Date(date).toLocaleDateString("ru-RU");
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }
    
    function applyVisualChanges() {
        setRefresh(!refresh);
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    function getTabNumber() {

        if (data?.data.controlTypeId === 0)
            return 0;

        if (data?.data.controlTypeId === 1 || data?.data.controlTypeId === 2)
            return 1;

        if (data?.data.controlTypeId === 3 || data?.data.controlTypeId === 4)
            return 2;

        return 0;
    }

    const handleSave = async () => {
        setIsSaving(true);
        
        const customStudents = students.filter((item) => item.registryDocumentId > 0 && item.gradeId > 0 || item.notAllowed !== 0 && item.notAllowed[0] === 1);
        request.students = customStudents.map((item) => {
            return {
                studentId: item.studentId,
                gradeId: item.gradeId,
                notAllowed: item.notAllowed !== 0 && item.notAllowed[0] === 1,
                registryDocumentId: item.registryDocumentId
            }
        })

        await createStatement({data: request}).unwrap().then((fulfilled) => { 
            if (fulfilled.isSuccess) {
                const tabNumber = getTabNumber();
                navigate(`/managestatements/${data?.data.sessionId}?tab=${tabNumber}`);
            }   
            else {
                alert(fulfilled.message);
                setIsSaving(false);
            }
        });
    };
    
    function calculateDeadline(date) {
        const add = date.getDay() === 7 ? 1
            : date.getDay() === 6 ? 2
                : date.getDay() === 5 ? 3
                    : 1;
        date.setDate(date.getDate() + add)
        return date;
    }
    
    return <GeneralLayout>
        <PageTitle title={`Создать ведомость`} />

        <Container>
            <Stack
                padding="120px 0px 0px 100px"
                direction="column"
                spacing={2}
            >
                <Stack mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {data === null || data === undefined
                            ? <CircularProgress/>
                            : <></>} Создать ведомость для группы {data?.data.groupName}
                    </Typography>
                    {(data !== null && data !== undefined)
                    ? (<><Typography variant="h5">по дисциплине {data.data.disciplineName}</Typography></>)
                    : (<></>)}
                </Stack>

                <Card>
                    <CardContent>
                        <Stack spacing={5}>
                            <Stack direction="row" spacing={5}>
                                <Stack spacing={1}>
                                    <Typography>№ ведомости</Typography>
                                    <TextField placeholder="№ ведомости"
                                               value={request.statementNumber}
                                               onChange={event => {request.statementNumber = event.target.value; applyVisualChanges()}}/>
                                </Stack>

                                <Stack spacing={1}>
                                    <Typography>Крайняя дата закрытия</Typography>
                                    <TextField placeholder="Крайняя дата закрытия"
                                               type="date"
                                               value={request.deadline}
                                               onChange={event => {request.deadline = event.target.value; applyVisualChanges()}}/>
                                </Stack>
                                
                                <Typography>
                                    <strong>Преподаватели:</strong>
                                    <br />
                                    {data?.data.employees.join(', ')}
                                    {data?.data.extraEmployees.length > 0 
                                        ? `, ${data?.data.extraEmployees.join(', ')}`
                                        : ''}
                                </Typography>
                            </Stack>

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={10}>#</TableCell>
                                        <TableCell>ФИО студента</TableCell>
                                        <TableCell>Недопуск</TableCell>
                                        <TableCell><></></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {students?.sort((left,right) => left.fullName > right.fullName ? 1 : -1).map((item, index) => {
                                        return (
                                            <TableRow>
                                                <TableCell><Typography fontSize="large"><strong>{index + 1}</strong></Typography></TableCell>
                                                <TableCell>{item.fullName}</TableCell>
                                                <TableCell>
                                                    <ToggleButtonGroup orientation="horizontal"
                                                                       value={item.notAllowed}
                                                                       onChange={(event, value) => {item.notAllowed = value; applyVisualChanges();}}
                                                                       size={"small"}>
                                                        <ToggleButton value={1} color={'error'}><RemoveCircleIcon /></ToggleButton>
                                                    </ToggleButtonGroup>
                                                </TableCell>
                                                <TableCell>{item.documents.length === 0
                                                ? <></>
                                                : <>
                                                    <RadioGroup onChange={(event) => {item.registryDocumentId = event.target.value}}>
                                                        {item.documents.map((doc) => {
                                                            return <FormControlLabel control={<Radio size={"small"} value={doc.registryDocumentId}/>} 
                                                                label={<Typography fontSize={"small"}>Перезачесть на основании документа <Link onClick={() => getCorrectFsLink(`https://fs.kubsau.ru/file/${doc.registryDocumentGuid}`)}
                                                                                                                                                  target={"_blank"}>{doc.documentNumber} от {toDateString(doc.documentDate)}</Link></Typography>} />
                                                        })}
                                                    </RadioGroup>

                                                    <Typography fontSize={'small'}>Оценка по перезачету</Typography>
                                                    <Stack maxWidth={250}>
                                                        <ToggleButtonGroup orientation="horizontal"
                                                                           exclusive
                                                                           fullWidth
                                                                           size={"small"}
                                                                           aria-label="Оценка"
                                                                           color="primary"
                                                                           value={item.gradeId}
                                                                           onChange={(event, value) => {item.gradeId = value; applyVisualChanges();}}>
                                                            <ToggleButton value={5} area-label="Отлично" color="success">{data?.data.controlType !== 1 ? '5' : 'Зачтено'}</ToggleButton>
                                                            {data?.data.controlType !== 1
                                                                ? <ToggleButton value={4} area-label="Хорошо" color="success">4</ToggleButton>
                                                                : <></> }
                                                            {data?.data.controlType !== 1
                                                                ? <ToggleButton value={3} area-label="Удовлетворительно" color="warning">3</ToggleButton>
                                                                : <></> }
                                                            <ToggleButton value={2} area-label="Неудовлетворительно"
                                                                          color="error">{data?.data.controlType !== 1 ? '2' : 'Не зачтено'}</ToggleButton>
                                                            {data?.data.controlType !== 1
                                                                ?
                                                                <ToggleButton value={7} area-label="н/я" color="error">н/я</ToggleButton>
                                                                : <></>}
                                                            <ToggleButton value={6} area-label="-" color="info">н/д</ToggleButton>
                                                            <ToggleButton value={1} area-label="-" color="info">-</ToggleButton>
                                                        </ToggleButtonGroup>
                                                    </Stack>
                                                </>}</TableCell>
                                            </TableRow>)
                                    })}
                                </TableBody>
                            </Table>

                            <Stack justifyContent="space-between">
                                {
                                    !isSaving
                                        ? <Button variant="contained" onClick={handleSave}>Сохранить</Button>
                                        : <CircularProgress/>
                                }
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </Container>
    </GeneralLayout>
}