import AttestationEditPage from "./pages/AttestationEditPage";
import AttestationsPage from "./pages/AttestationsPage";
import {Auth} from "./pages/Auth";
import CreatePracticePage from "./pages/CreatePractice"
import {DashboardAppPage} from "./pages/DashboardAppPage";
import DisciplineGroupJournalPage from "./pages/DisciplineGroupJournalPage";
import EditSubGroupsPage from "./pages/EditSubGroupsPage";
import EditThemesPage from "./pages/EditThemesPage";
import EmployeeStatementsPage from "./pages/EmployeeStatementsPage";
import GroupPage from "./pages/Group"
import {GroupInfo} from "./pages/GroupInfo";
import HomePage from "./pages/Home"
import JournalEditPage from "./pages/JournalEditPage";
import JournalMainPage from "./pages/JournalMainPage";
import ManagePracticesByCoursesPage from "./pages/ManagePracticesByCourses"
import ManagePracticesForGroupPage from "./pages/ManagePracticesForGroup"
import MyStatementsPage from "./pages/MyStatementsPage";
import SessionsPage from "./pages/SessionsPage";
import StatementResultsPage from "./pages/StatementResultsPage";
import StudentPage from "./pages/Student"
import StudentSkipStatisticPage from "./pages/StudentSkipStatisticPage";
import CreateSessionPage from "./pages/CreateSessionPage";
import EditSessionSchedulePage from "./pages/EditSessionSchedulePage";
import EditSessionRetakesPage from "./pages/EditSessionRetakesPage";
import ApproveSessionPage from "./pages/ApproveSessionPage";
import ManageStatementsPage from "./pages/ManageStatementsPage";
import CreateStatementPage from "./pages/CreateStatementPage";
import EditStatementPage from "./pages/EditStatementPage";
import FacultyAttestationsPage from "./pages/FacultyAttestationsPage";
import CreateAttestationPage from "./pages/CreateAttestationPage";
import EditAttestationPage from "./pages/EditAttestationPage";
import AttestationDetailsPage from "./pages/AttestationDetailsPage";
import EmployeeEditStatementPage from "./pages/EmployeeEditStatementPage";
import CoursePracticesPage from "./pages/CoursePracticesPage";
import EditPracticePage from "./pages/EditPracticePage";
import CreatePracticeStatementPage from "./pages/CreatePracticeStatementPage";
import PracticeStatementResultsPage from "./pages/PracticeStatementResultsPage";
import MyPracticeStatementsPage from "./pages/MyPracticeStatementsPage";
import EditPracticeStatementPage from "./pages/EditPracticeStatementPage";
import UmuMainPage from "./pages/UmuMainPage";
import SessionApproveListPage from "./pages/SessionApproveListPage";
import SessionApprovePage from "./pages/SessionApprovePage";
import PracticeApproveListPage from "./pages/PracticeApproveListPage";
import PracticeApproveInfoPage from "./pages/PracticeApproveInfoPage";
import CreateSessionStopRequestPage from "./pages/CreateSessionStopRequestPage";
import SessionStopRequestListPage from "./pages/SessionStopRequestListPage";
import SessionStopRequestApprovalPage from "./pages/SessionStopRequestApprovalPage";
import CreateOutsidePlanPassingPage from "./pages/CreateOutsidePlanPassingPage";
import OutsidePlanPassingListPage from "./pages/OutsidePlanPassingListPage";
import OutsidePlanPassingsApprovalListPage from "./pages/OutsidePlanPassingsApprovalListPage";
import ApproveOutsidePlanPassingRequestPage from "./pages/ApproveOutsidePlanPassingRequestPage";
import OutsidePlanPassingDetailsPage from "./pages/OutsidePlanPassingDetailsPage";
import EditBindingsPage from "./pages/EditBindingsPage";
import CreateVirtualGroupPage from "./pages/CreateVirtualGroupPage";
import VirtualGroupJournalPage from "./pages/VirtualGroupJournalPage";
import StudentSearchPage from "./pages/StudentSearchPage";
import StudentCardPage from "./pages/StudentCardPage";
import CuratorsPage from "./pages/CuratorsPage";
import DocumentsPage from "./pages/DocumentsPage";
import CertificatesOfSkippingPage from "./pages/CertificatesOfSkippingPage";
import CreateCertificateOfSkippingPage from "./pages/CreateCertificateOfSkippingPage";
import RegistryOfDocumentsPage from "./pages/RegistryOfDocumentsPage";
import CreateDocumentInRegistryPage from "./pages/CreateDocumentInRegistryPage";
import JournalMainOldPage from "./pages/JournalMainOldPage";
import EditSessionDisciplineDetailPage from "./pages/EditSessionDisciplineDetailPage";
import MaintenancePage from "./pages/MaintenancePage";
import SessionManagementByAdminPage from "./pages/SessionManagementByAdminPage";

export const AppRoutes = [
	{
		element: <HomePage />,
		path: "/",
	},
    {
        element: <DashboardAppPage />,
        path: "/app",
    },
    {
        path: '/journalmain',
        element: <JournalMainPage />
    },
    {
        path: '/journalmainold',
        element: <JournalMainOldPage />
    },
    {
        path: '/journaledit/:groupFlowTeamId/:virtualGroupId',
        element: <JournalEditPage />
    },
    {
        path: '/attestations',
        element: <AttestationsPage />
    },
    {
        path: '/attestationedit/:id',
        element: <AttestationEditPage />
    },
    {
        path: '/sessions',
        element: <SessionsPage />
    },
    {
        path: '/createsession/:id',
        element: <CreateSessionPage />
    },
    {
        path: '/editsessionschedule/:id',
        element: <EditSessionSchedulePage />
    },
    {
        path: '/editsessionretakesschedule/:id',
        element: <EditSessionRetakesPage />
    },
    {
        path: '/approvesession/:id',
        element: <ApproveSessionPage />
    },
    {
        path: '/employeestatements',
        element: <EmployeeStatementsPage />
    },
    {
        path: '/managestatements/:id',
        element: <ManageStatementsPage />
    },
    {
        path: '/createstatement/:id',
        element: <CreateStatementPage />
    },
    {
        path: '/mystatements',
        element: <MyStatementsPage />
    },
    {
        path: '/editthemes',
        element: <EditThemesPage />
    },
    {
        path: '/disciplinegroupjournal/:courseid/:disciplineid',
        element: <DisciplineGroupJournalPage />
    },
    {
        path: '/editsubgroups/:courseid/:disciplineid',
        element: <EditSubGroupsPage />
    },
    {
        path: '/studentskipstatistic',
        element: <StudentSkipStatisticPage />
    },
    {
        path: '/statementresults/:id',
        element: <StatementResultsPage />
    },
    {
        path: '/editfacultystatement/:id',
        element: <EditStatementPage />
    },
    {
        path: '/group-info',
        element: <GroupInfo/>,
    },
	{
		path: '/students/:id',
		element: <StudentPage />,
	},
	{
		path: '/faculty/groups/:id',
		element: <GroupPage />,
	},
	{
		path: 'faculty-practices-courses',
		element: <ManagePracticesByCoursesPage />,
	},
	{
		path: '/faculty-practices-for-group/:groupId',
		element: <ManagePracticesForGroupPage />,
	},
	{
		path: '/faculty-create-practice/:groupId',
		element: <CreatePracticePage />
	},
    {
        path: '/auth/:externalToken?',
        element: <Auth />
    },
    {
        path: '/facultyattestations',
        element: <FacultyAttestationsPage />
    },
    {
        path: '/createattestation/:id',
        element: <CreateAttestationPage />
    },
    {
        path: '/editattestation/:id',
        element: <EditAttestationPage />
    },
    {
        path: '/attestationdetails/:id',
        element: <AttestationDetailsPage />
    },
    {
        path: '/employeeeditstatement/:id',
        element: <EmployeeEditStatementPage />
    },
    {
        path: '/course-practices/:id',
        element: <CoursePracticesPage />
    },
    {
        path: '/editpractice/:practiceId/:groupId',
        element: <EditPracticePage />
    },
    {
        path: '/create-practice-statement/:practiceId/:groupId',
        element: <CreatePracticeStatementPage />
    },
    {
        path: '/practice-statement-results/:statementId',
        element: <PracticeStatementResultsPage />
    },
    {
        path: '/my-practice-statements',
        element: <MyPracticeStatementsPage />
    },
    {
        path: '/edit-practice-statement/:id',
        element: <EditPracticeStatementPage />
    },
    {
        path: '/umu-main',
        element: <UmuMainPage />
    },
    {
        path: '/session-approve-list',
        element: <SessionApproveListPage />
    },
    {
        path: '/session-approve/:sessionId',
        element: <SessionApprovePage />
    },
    {
        path: '/practice-approve-list',
        element: <PracticeApproveListPage />
    },
    {
        path: '/practice-approve-info/:practiceId',
        element: <PracticeApproveInfoPage />
    },
    {
        path: '/create-session-stop-request/:sessionId',
        element: <CreateSessionStopRequestPage />
    },
    {
        path: '/session-stop-request-list',
        element: <SessionStopRequestListPage />
    },
    {
        path: '/session-stop-request-approval/:requestId/:sessionId',
        element: <SessionStopRequestApprovalPage />
    },
    {
        path: '/create-outside-plan-passing/:sessionId',
        element: <CreateOutsidePlanPassingPage />
    },
    {
        path: '/outside-plan-passing-list/:sessionId',
        element: <OutsidePlanPassingListPage />
    },
    {
        path: '/outside-plan-passings-approval-list',
        element: <OutsidePlanPassingsApprovalListPage />
    },
    {
        path: '/approve-outside-plan-passing-request/:id',
        element: <ApproveOutsidePlanPassingRequestPage />
    },
    {
        path: '/outside-plan-passing-details/:id',
        element: <OutsidePlanPassingDetailsPage />
    },
    {
        path: '/edit-bindings/:courseId/:disciplineId',
        element: <EditBindingsPage />
    },
    {
        path: `/create-virtual-group`,
        element: <CreateVirtualGroupPage />
    },
    {
        path: `/virtual-group-journal/:virtualGroupId`,
        element: <VirtualGroupJournalPage />
    },
    {
        path: `/search-student`,
        element: <StudentSearchPage />
    },
    {
        path: `/student-card/:id`,
        element: <StudentCardPage />
    },
    {
        path: `/curators`,
        element: <CuratorsPage />
    },
    {
        path: `/documents`,
        element: <DocumentsPage />
    },
    {
        path: `/certificates-of-skipping`,
        element: <CertificatesOfSkippingPage />
    },
    {
        path: `/create-certificate-of-skipping`,
        element: <CreateCertificateOfSkippingPage />
    },
    {
        path: `/registry-of-documents`,
        element: <RegistryOfDocumentsPage />
    },
    {
        path: `/create-document-in-registry/:id?`,
        element: <CreateDocumentInRegistryPage />
    },
    {
        path: `/editsessiondisciplinedetail/:id`,
        element: <EditSessionDisciplineDetailPage />
    },
    {
        path: `/maintenance`,
        element: <MaintenancePage />
    },
    {
        path: `/sessionmanagementbyadmin/:id`,
        element: <SessionManagementByAdminPage />
    },
];

export default AppRoutes;
